<template>
  <div class="store-index d-flex justify-center">
    <div class="card-wrap d-flex flex-column align-center w-full">
      <pageTitle icon="icon-store-red" text="storePoints"></pageTitle>

      <infoBlock></infoBlock>

      <v-data-table
        class="store-plans"
        :headers="headers"
        :items="items"
        item-class="items-row"
        hide-default-footer
        mobile-breakpoint="200"
        :items-per-page="-1"
      >
        <template v-slot:item.action="{item}">
          <div class="red--text text-decoration-underline can-click" @click="$router.push({name: 'Store-Pay', params: {lang: $store.state.locale, amount: item.plan}})">{{ $t('toStore') }}</div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
import infoBlock from '@/components/infoBlock.vue'
export default {
  name: "Store",
  mixins: [base],
  data(){
    return {
      headers: [
        { text: this.$t('rate'), value: 'rate', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('plan')}(TBT)`, value: 'plan', sortable: false, align: 'right', class: 'red white--text' },
        { text: `${this.$t('storeReward')}(TBT)`, value: 'reward', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('directRefer')}(%)`, value: 'directRefer', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('algebra')}(%)`, value: 'algebra', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('out')}(TBT)`, value: 'out', sortable: false, align: 'center', class: 'red white--text' },
        { text: this.$t('store'), value: 'action', sortable: false, align: 'center', class: 'red white--text' },
      ],
    }
  },
  components:{
    pageTitle,
    infoBlock
  },
  computed:{
    items(){
      return [
        {
          rate: 1,
          plan: 3000,
          reward: 6,
          directRefer: 6,
          algebra: '--',
          out: 3,
        },
        {
          rate: 2,
          plan: 6000,
          reward: 8,
          directRefer: 6,
          algebra: '--',
          out: 4,
        },
        {
          rate: 3,
          plan: 15000,
          reward: 10,
          directRefer: 7,
          algebra: '--',
          out: 5,
        },
        {
          rate: 4,
          plan: 30000,
          reward: 12,
          directRefer: 8,
          algebra: '--',
          out: 6,
        },
        {
          rate: 5,
          plan: 60000,
          reward: 14,
          directRefer: 9,
          algebra: '--',
          out: 7,
        },
        {
          rate: 6,
          plan: 150000,
          reward: 16,
          directRefer: 10,
          algebra: '--',
          out: 8,
        },
        {
          rate: 7,
          plan: 450000,
          reward: 18,
          directRefer: 10,
          algebra: '--',
          out: 9,
        },
        {
          rate: 8,
          plan: 1050000,
          reward: 20,
          directRefer: 10,
          algebra: '--',
          out: 10,
        },
      ]
    }
  },
}
</script>
<style lang="scss">
.store-index{
  .store-plans{
    width: 90%;
    tr:nth-child(2n){
      background-color: #EF9A9A;
    }
  }
}
</style>